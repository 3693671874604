import { createRouter, createWebHistory } from 'vue-router'
import ENView from '../views/ENView.vue'

const routes = [
  {
    path: '/',
    name: 'en',
    component: ENView
  },
  {
      path: '/fr',
      name: 'fr',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/FRView.vue')
  },
    {
        path: '/personal-data-policy',
        name: 'personal-data-policy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/ENPolicyView.vue')
    },
    {
        path: '/fr/politique-de-donnees-personnelles',
        name: 'politique-de-donnees-personnelles',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/FRPolicyView.vue')
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
